import React from 'react';
import { useFormState } from 'react-final-form';
import { Button, ButtonProps, Spinner } from '../../components';
import { useIsDisabled } from '../useIsDisabled';

export function Submit({
  disabled: propDisabled,
  children = 'Submit',
  ...props
}: Omit<ButtonProps, 'type'>): React.ReactElement {
  const { submitting } = useFormState({ subscription: { submitting: true } });

  const disabled = useIsDisabled(propDisabled);

  return (
    <Button {...props} type="submit" disabled={submitting || disabled}>
      {submitting && <Spinner size="sm" aria-hidden={true} />} {children}
    </Button>
  );
}
