import { useId } from '../hooks';
import { useIsDisabled } from './useIsDisabled';

export function useFieldProps<P extends { disabled?: boolean; id?: string }>({
  disabled: propDisabled = false,
  id: propId,
  ...rest
}: P): Omit<P, 'disabled' | 'id'> & { disabled: boolean; id: string } {
  const id = useId('field', propId);
  const disabled = useIsDisabled(propDisabled);

  return {
    id,
    disabled,
    ...rest,
  };
}
