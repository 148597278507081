import { parseISO, formatISO, isValid } from 'date-fns';

// Date | null in widget Date | undefined in state
export const parseDate = (value: any): any => {
  return value && isValid(value)
    ? formatISO(value, { representation: 'date' })
    : undefined;
};

export const formatDate = (value: any): any => {
  return value ? parseISO(value) : null;
};
