import React from 'react';
import cx from 'classnames';
import { SpinnerProps } from './types';

export function Spinner(props: SpinnerProps): React.ReactElement {
  const {
    type = 'border',
    variant,
    size = 'md',
    className: outerClassname,
    ...rest
  } = props;
  const className = cx(
    `spinner-${type}`,
    variant && `text-${variant}`,
    size === 'sm' ? `spinner-${type}-sm` : undefined,
    outerClassname
  );

  return (
    <div className={className} role="status" {...rest}>
      <span className="sr-only">Loading...</span>
    </div>
  );
}
