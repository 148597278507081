import React from 'react';
import { useField } from 'react-final-form';
import { Check } from '../../components';
import { fieldSubscription } from '../const';
import { useFieldProps } from '../useFieldProps';
import { FormGroup } from '../FormGroup';
import { CheckFieldProps } from './types';

export function CheckField(props: CheckFieldProps): React.ReactElement {
  const {
    name,
    className,
    label,
    fieldLabel,
    help,
    validate,
    parse,
    format,
    ...rest
  } = useFieldProps(props);

  const { input, meta } = useField<any, HTMLInputElement>(name, {
    subscription: fieldSubscription,
    type: 'checkbox',
    validate,
    parse,
    format,
  });

  const fieldProps = {
    ...input,
    error: meta.invalid && meta.touched,
    ...rest,
  };

  return (
    <FormGroup
      className={className}
      label={label}
      id={rest.id}
      help={help}
      meta={meta}
      required={fieldProps.required}
    >
      <Check label={fieldLabel} {...fieldProps} />
    </FormGroup>
  );
}
