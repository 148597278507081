import { useRef } from 'react';

interface DebounceOptions {
  leading: boolean;
  trailing: boolean;
}

export function useDebounce(
  functionToExecute: (...args: any[]) => void,
  timing = 500,
  options: DebounceOptions = { leading: false, trailing: true }
): any {
  const timerRef = useRef<any>();
  const firstCallFlag = useRef<boolean>();

  const debouncedFunction = (...args: any[]): any => {
    if (!firstCallFlag.current && options.leading) {
      setTimeout(() => {
        functionToExecute(...args);
      }, 10);
      firstCallFlag.current = true;
    }
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      if (options.trailing) {
        functionToExecute(...args);
      }
      firstCallFlag.current = false;
    }, timing);
  };

  return debouncedFunction;
}
