import React from 'react';
import { THeadProps, SortOrderType } from './types';
import { Entity } from '..';

function toggleSortOrder(
  currentSortOrder?: SortOrderType
): SortOrderType | undefined {
  // DESC -> ASC -> NONE
  switch (currentSortOrder) {
    case 'DESC':
      return 'ASC';
    case 'ASC':
      return undefined;
    default:
      return 'DESC';
  }
}

export function THead<T extends Entity = {}>(
  props: THeadProps<T>
): React.ReactElement {
  const { columns, className, sort, onSort } = props;

  return (
    <>
      <colgroup>
        {columns.map(({ width, minWidth, maxWidth }, i) => (
          <col
            key={i}
            style={{
              width,
              minWidth,
              maxWidth,
            }}
          />
        ))}
      </colgroup>
      <thead className={className}>
        <tr>
          {columns.map(({ key, sortable, content, headerClassName }, i) => {
            const sorted = sort && sort.key === key ? sort.order : undefined;
            const props = sortable
              ? {
                  style: {
                    cursor: 'pointer',
                  },
                  onClick: onSort
                    ? () => {
                        const order = toggleSortOrder(sorted);
                        onSort(order ? { order, key } : false);
                      }
                    : undefined,
                }
              : undefined;

            return (
              <th key={i} className={headerClassName} {...props}>
                {content}
                {sorted && (
                  <span className="ml-1">
                    {sorted === 'DESC' ? '🔽' : '🔼'}
                  </span>
                )}
              </th>
            );
          })}
        </tr>
      </thead>
    </>
  );
}
