import React from 'react';
import { FieldErrorMeta } from './types';

export function ErrorTooltip({
  submitError,
  dirtySinceLastSubmit,
  touched,
  error,
}: FieldErrorMeta): React.ReactElement | null {
  const sync = error && touched ? error : undefined;
  if (sync) {
    return <div className="invalid-tooltip d-block">{sync}</div>;
  }

  const submit = submitError && !dirtySinceLastSubmit ? submitError : undefined;

  if (submit) {
    return <div className="invalid-tooltip d-block">{submit}</div>;
  }
  return null;
}
