import React, { forwardRef, Ref } from 'react';
import cx from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { TextAreaProps } from './types';

export const TextArea = forwardRef(function TextArea(
  props: TextAreaProps,
  ref: Ref<HTMLTextAreaElement>
) {
  const { size, error, className: outerClassname, autosize, ...rest } = props;
  const className = cx(
    'form-control',
    size && `form-control-${size}`,
    {
      'is-invalid': error,
    },
    outerClassname
  );

  if (autosize) {
    return (
      <TextareaAutosize
        className={className}
        minRows={rest.rows}
        {...rest}
        // FIXME
        ref={ref as any}
      />
    );
  }

  return <textarea className={className} {...rest} ref={ref} />;
});
