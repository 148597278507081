import React from 'react';
import cx from 'classnames';
import { CheckProps } from './types';
import { useId } from '../../hooks';
import { getCheckClassNames } from './getCheckClassNames';

export function Check(props: CheckProps): React.ReactElement {
  const {
    variant = 'simple',
    inline,
    error,
    className: outerClassname,
    id: propId,
    label,
    ...rest
  } = props;

  const id = useId('field', propId);

  const {
    containerClassName,
    inputClassName,
    labelClassName,
  } = getCheckClassNames(variant, inline);

  return (
    <div className={cx(containerClassName, outerClassname)}>
      <input
        className={cx(inputClassName, { 'is-invalid': error })}
        {...rest}
        id={id}
        type="checkbox"
      />
      <label className={labelClassName} htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
