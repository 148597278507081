import React from 'react';
import { useField } from 'react-final-form';
import { Input } from '../../components';
import { fieldSubscription } from '../const';
import { FormGroup } from '../FormGroup';
import { useFieldProps } from '../useFieldProps';
import { IntFieldProps } from './types';

// TODO: DRY with TextField
// TODO: negative values
// TODO: keep previous value on fail

function parse(value: string): any {
  if (value !== '') {
    const parsed = parseInt(value, 10);
    if (!isNaN(parsed)) {
      if (
        parsed < Number.MAX_SAFE_INTEGER &&
        parsed > -Number.MAX_SAFE_INTEGER
      ) {
        return parsed;
      }
    }
  }

  return null;
}

function format(value: any): any {
  return value === null || value === undefined ? '' : value.toString();
}

export function IntField(props: IntFieldProps): React.ReactElement {
  const { name, className, label, help, validate, ...rest } = useFieldProps(
    props
  );
  const { input, meta } = useField<string, HTMLInputElement>(name, {
    format,
    parse,
    subscription: fieldSubscription,
    allowNull: true,
    validate,
  });

  const fieldProps = {
    ...input,
    error: meta.invalid && meta.touched,
    ...rest,
  };

  return (
    <FormGroup
      className={className}
      label={label}
      id={rest.id}
      help={help}
      meta={meta}
      required={fieldProps.required}
    >
      <Input {...fieldProps} />
    </FormGroup>
  );
}
