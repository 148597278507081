import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { Transition } from 'react-transition-group';
import { LoadingContainerProps } from './types';
import { Spinner } from '../Spinner';
import './LoadingContainer.scss';

export function LoadingContainer(
  props: LoadingContainerProps
): React.ReactElement {
  const { loading = false, delay = 300, children, testId } = props;
  const nodeRef = useRef(null);
  const [spin, setSpin] = useState(() => {
    const shouldDelay = !!loading && !!delay;
    return loading && !shouldDelay;
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setSpin(loading);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [loading, delay]);

  return (
    <Transition
      nodeRef={nodeRef}
      in={true}
      appear={true}
      timeout={150}
      exit={false}
    >
      {state => (
        <div
          className={cx('loading-container', state)}
          data-testid={testId}
          ref={nodeRef}
        >
          {spin && (
            <div className="loading-container-spinner">
              <Spinner variant="primary" />
            </div>
          )}
          <div className={cx('loading-container-wrapper', { loading: spin })}>
            {children}
          </div>
        </div>
      )}
    </Transition>
  );
}
