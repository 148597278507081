import React, { useRef, useCallback } from 'react';
import { useField } from 'react-final-form';
import { default as ReactDatePicker } from 'react-datepicker';
import { DatePicker } from '../../components';
import { fieldSubscription } from '../const';
import { FormGroup } from '../FormGroup';
import { DateRangePickerFieldProps } from './types';
import { parseDate, formatDate } from '../DatePickerField/utils';
import { useIsDisabled } from '../useIsDisabled';
import { ErrorTooltip } from '../FormGroup/ErrorTooltip';

export function DateRangePickerField(
  props: DateRangePickerFieldProps
): React.ReactElement {
  const {
    fromName,
    fromPlaceholder,
    toName,
    toPlaceholder,
    disabled: propDisabled,
    className,
    label,
    help,
    validate,
    maxDate,
    fromProps: fromOverride,
    toProps: toOverride,
    ...rest
  } = props;

  const toRef = useRef<ReactDatePicker>(null);
  const onSelect = useCallback(() => {
    toRef.current!.setFocus();
  }, []);

  const disabled = useIsDisabled(propDisabled);

  const {
    input: { value: fromSelected, ...fromInput },
    meta: fromMeta,
  } = useField<Date | null, any>(fromName, {
    subscription: fieldSubscription,
    parse: parseDate,
    format: formatDate,
    validate,
  });

  const {
    input: { value: toSelected, ...toInput },
    meta: toMeta,
  } = useField<Date | null, any>(toName, {
    subscription: fieldSubscription,
    parse: parseDate,
    format: formatDate,
    validate,
  });

  const fromProps = {
    ...fromInput,
    selected: fromSelected,
    error: fromMeta.invalid && fromMeta.touched,
    ...rest,
    maxDate,
    selectsStart: true,
    endDate: toSelected,
    disabled,
    placeholder: fromPlaceholder,
    onSelect,
    ...fromOverride,
  };

  const openToDate = !toSelected && fromSelected ? fromSelected : undefined;

  const toProps = {
    ...toInput,
    selected: toSelected,
    error: toMeta.invalid && toMeta.touched,
    ...rest,
    maxDate,
    selectsEnd: true,
    startDate: fromSelected,
    endDate: toSelected,
    minDate: fromSelected,
    disabled,
    placeholder: toPlaceholder,
    openToDate,
    ...toOverride,
  };

  return (
    <FormGroup
      className={className}
      label={label}
      id={rest.id}
      help={help}
      required={rest.required}
    >
      <div className="date-range-picker d-flex">
        <div className="date-range-picker-from position-relative">
          <DatePicker {...fromProps} />
          <ErrorTooltip {...fromMeta} />
        </div>
        <div className="ml-1 date-range-picker-to position-relative">
          <DatePicker {...toProps} ref={toRef} />
          <ErrorTooltip {...toMeta} />
        </div>
      </div>
    </FormGroup>
  );
}
