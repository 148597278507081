import React from 'react';
import cx from 'classnames';
import { FormGroupProps } from './types';
import './FormGroup.scss';
import { ErrorTooltip } from './ErrorTooltip';

export function FormGroup({
  className,
  label,
  id,
  children,
  help,
  meta,
  required,
}: FormGroupProps): React.ReactElement {
  return (
    <div className={cx('form-group', { required }, className)}>
      {label && <label htmlFor={id}>{label}</label>}
      {children}
      {help && <small className="form-text text-muted">{help}</small>}
      <ErrorTooltip {...meta} />
    </div>
  );
}
