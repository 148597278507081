import { Sort } from '../Table';

export function serializeSort(
  sort?: Sort<any> | false | undefined
): string | undefined {
  if (sort === false) {
    return '';
  }
  if (sort) {
    const { key, order } = sort;
    return `${order === 'DESC' ? '-' : ''}${key.replace('.', '__')}`;
  }

  return undefined;
}

export function parseSort(sortBy?: string): Sort<any> | undefined | false {
  if (sortBy === '') {
    return false;
  }
  if (sortBy) {
    const desc = sortBy.startsWith('-');
    return {
      key: (desc ? sortBy.substring(1) : sortBy).replace('__', '.'),
      order: desc ? 'DESC' : 'ASC',
    };
  }

  return undefined;
}
