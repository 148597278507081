import React from 'react';
import { Entity } from '../types';
import { Table } from '../Table';
import { Pagination } from '../Pagination';
import { Alert } from '../Alert';
import { LoadingContainer } from '../LoadingContainer';
import { ListProps } from './types';
import { useList } from './useList';
import { ListContext } from './ListContext';

export function List<T extends Entity>({
  children,
  classNames = {},
  emptyElement = <Alert variant="info">Nothing here</Alert>,
  pageSizes,
  after,
  columns,
  tfoot,
  ...props
}: ListProps<T>): React.ReactElement {
  const { className, tableClassName, tableHeadClassName } = classNames;
  const list = useList(props);
  const {
    loading,
    data,
    sort,
    setSort,
    page,
    count,
    size,
    setPage,
    setSize,
  } = list;
  const totalPages = Math.ceil(count / size);
  const nothing = !loading && data.length === 0;
  return (
    <div className={className}>
      <ListContext.Provider value={list}>
        {children}
        <LoadingContainer loading={loading} testId="list-loader">
          {nothing ? (
            emptyElement
          ) : (
            <Table<T>
              columns={columns}
              className={tableClassName}
              headClassName={tableHeadClassName}
              data={data}
              onSort={setSort}
              sort={sort}
              tfoot={tfoot}
            />
          )}
          {(totalPages > 1 || pageSizes) && (
            <Pagination
              page={page}
              totalPages={totalPages}
              goToPage={setPage}
              pageSize={size}
              setPageSize={setSize}
              pageSizes={pageSizes}
            />
          )}
        </LoadingContainer>
      </ListContext.Provider>
    </div>
  );
}
