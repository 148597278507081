import { useCallback, useState } from 'react';
import { FormApi, FORM_ERROR } from 'final-form';
import { useAPI, RequestArgs, RequestBody, RequestFailure } from '../api';
import { FormData, SubmitHandler, SubmissionErrors } from './types';

export interface SubmitHandlerOptions<FV extends FormData, RV> {
  onSuccess?: (v: RV, form: FormApi<FV>) => void;
  onFailure?: (
    f: RequestFailure<any>,
    v: FV,
    form: FormApi<FV>
  ) => void | SubmissionErrors;
  mapValues?: (v: FV, form: FormApi<FV>) => RequestBody;
}

export function useSubmitHandler<FV extends FormData, RV = FV>(
  request: Omit<RequestArgs, 'body'>,
  options: SubmitHandlerOptions<FV, RV> = {}
): SubmitHandler<FV> {
  const [, /* state */ setState] = useState();
  const api = useAPI();

  return useCallback(
    async (values: FV, form: FormApi<FV>): Promise<FormData | void> => {
      const { onSuccess, onFailure, mapValues } = options;

      const result = await api.request<RV, Record<string, unknown>>({
        ...request,
        body: mapValues ? mapValues(values, form) : values,
      });

      if (result.ok) {
        onSuccess && onSuccess(result.data, form);
      } else {
        if (result.status === 400 && result.data) {
          const { non_field_errors, ...error } = result.data;

          if (non_field_errors) {
            error[FORM_ERROR] = non_field_errors;
          }
          return error;
        }
        if (onFailure) {
          return onFailure(result, values, form);
        } else {
          setState(() => {
            throw result.error;
          });
          throw result.error;
        }
      }
    },
    [api, request, options]
  );
}
