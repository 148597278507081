// 4xxx
export class RequestError extends Error {
  status?: number;
  constructor(message: string, status?: number) {
    super(message);
    this.name = 'RequestError';
    this.status = status;
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, RequestError.prototype);
  }
}

// 5xxx
export class InternalError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InternalError';
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, InternalError.prototype);
  }
}

export class TimeoutError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TimeoutError';
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, TimeoutError.prototype);
  }
}
