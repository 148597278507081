import { useMemo, useCallback, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Entity } from '..';
import { Sort } from '../Table';
import eq from 'fast-deep-equal';
import { ControlState, Controller } from './types';
import { parseQuery, serializQuery } from './queryUtils';

export function useQueryControls<T extends Entity, F = any>(
  defaultPageSize?: number,
  defaultSort?: Sort<T>,
  defaultFilter?: F
): [ControlState<T, F>, Controller<T, F>] {
  const isMounted = useRef(true);

  const location = useLocation();
  const navigate = useNavigate();

  const defaultProps: ControlState<any, any> = useMemo(
    () => ({
      page: 1,
      size: defaultPageSize || 15,
      sort: defaultSort,
      filter: defaultFilter,
    }),
    [defaultSort, defaultPageSize, defaultFilter]
  );

  const currentState = useMemo(() => {
    const parsedProps = parseQuery(location.search);
    return {
      ...defaultProps,
      ...parsedProps,
      filter: {
        ...defaultProps.filter,
        ...parsedProps.filter,
      },
    };
  }, [location.search, defaultProps]);

  const updateQueryState = useCallback(
    (part: Partial<ControlState<T, F>>) => {
      if (!isMounted) {
        return;
      }

      const newState: any = {
        ...currentState,
        ...part,
      };

      const qs = eq(newState, defaultProps) ? '' : serializQuery(newState);
      if (location.search !== qs) {
        navigate(`${location.pathname}${qs}`, { replace: false });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, defaultSort]
  );

  const controller: Controller<T, F> = useMemo(
    () => ({
      setPage: (page: number) => updateQueryState({ page }),
      setSize: (size: number) => updateQueryState({ size, page: 1 }),
      setSort: (sort?: Sort<T> | false) => updateQueryState({ sort, page: 1 }),
      setFilter: (filter: F | undefined) =>
        updateQueryState({ filter, page: 1 }),
    }),
    [updateQueryState]
  );

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return [currentState, controller];
}
