import { parse, stringify } from 'qs';
import { ControlState } from './types';
import { parseSort, serializeSort } from './sortUtils';

export function parseQuery(
  searchString: string
): Partial<ControlState<any, any>> {
  const { p, s, o, ...filter } = parse(searchString, {
    ignoreQueryPrefix: true,
  });

  const res: Partial<ControlState<any, any>> = {};
  if (p) {
    res.page = parseInt(p as string, 10);
  }

  if (s) {
    res.size = parseInt(s as string, 10);
  }

  if (typeof o !== 'undefined') {
    res.sort = parseSort(o as string);
  }
  return {
    ...res,
    filter,
  };
}

export function serializQuery({
  page,
  size,
  sort,
  filter,
}: Partial<ControlState<any, any>>): string {
  return stringify(
    {
      p: page,
      s: size,
      o: serializeSort(sort),
      ...filter,
    },
    { addQueryPrefix: true }
  );
}
