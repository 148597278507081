import cx from 'classnames';
import { CheckVariant } from './types';

export function getCheckClassNames(
  variant: CheckVariant,
  inline?: boolean
): {
  containerClassName: string;
  inputClassName: string;
  labelClassName: string;
} {
  const containerClassName =
    variant === 'simple'
      ? cx('form-check', { 'form-check-inline': inline })
      : cx(
          'custom-control',
          variant === 'switch' ? 'custom-switch' : 'custom-checkbox',
          {
            'custom-control-inline': inline,
          }
        );

  const inputClassName =
    variant === 'simple' ? 'form-check-input' : 'custom-control-input';
  const labelClassName =
    variant === 'simple' ? 'form-check-label' : 'custom-control-label';

  return {
    containerClassName,
    inputClassName,
    labelClassName,
  };
}
