import React from 'react';
import cx from 'classnames';
import { PageRangeProps } from './types';

export function PageRange({
  page,
  totalPages,
  goToPage,
}: PageRangeProps): React.ReactElement {
  const disablePrev = !(page > 1);
  const disableNext = !(page < totalPages);

  return (
    <ul className="pagination mb-0">
      <li className={cx('page-item', { disabled: disablePrev })}>
        <button
          aria-label="First"
          className="page-link"
          onClick={() => goToPage(1)}
          disabled={disablePrev}
        >
          <span aria-hidden="true">«</span>
        </button>
      </li>
      <li className={cx('page-item', { disabled: disablePrev })}>
        <button
          aria-label="Previous"
          className="page-link"
          onClick={() => goToPage(page - 1)}
          disabled={disablePrev}
        >
          <span aria-hidden="true">‹</span>
        </button>
      </li>
      <li className={cx('page-item', { disabled: disableNext })}>
        <button
          className="page-link"
          aria-label="Next"
          onClick={() => goToPage(page + 1)}
          disabled={disableNext}
        >
          <span aria-hidden="true">›</span>
        </button>
      </li>
      <li className={cx('page-item', { disabled: disableNext })}>
        <button
          className="page-link"
          aria-label="Last"
          onClick={() => goToPage(totalPages)}
          disabled={disableNext}
        >
          <span aria-hidden="true">»</span>
        </button>
      </li>
    </ul>
  );
}
