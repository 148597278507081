import React, { forwardRef, Ref } from 'react';
import cx from 'classnames';
import { default as ReactDatePicker } from 'react-datepicker';
import { enGB } from 'date-fns/locale';
import './DatePicker.scss';
import { DatePickerProps } from './types';

// https://github.com/Hacker0x01/react-datepicker/issues/2195
const defaultMaxDate = new Date('December 31, 9999 00:00:00');

export const DatePicker = forwardRef(function DatePicker(
  props: DatePickerProps,
  ref: Ref<ReactDatePicker>
): React.ReactElement {
  const {
    size,
    error,
    className: outerClassname,
    placeholder,
    maxDate = defaultMaxDate,
    ...rest
  } = props;
  const className = cx(
    'form-control',
    size && `form-control-${size}`,
    {
      'is-invalid': error,
    },
    outerClassname
  );

  return (
    <ReactDatePicker
      ref={ref}
      className={className}
      {...rest}
      maxDate={maxDate}
      placeholderText={placeholder}
    />
  );
});

DatePicker.defaultProps = {
  dateFormat: 'dd.MM.yyyy',
  placeholder: 'DD.MM.YYYY',
  locale: enGB,
  autoComplete: 'off',
};
