import React from 'react';
import cx from 'classnames';
import { AlertProps } from './types';

export function Alert({
  variant = 'primary',
  heading,
  children,
  className,
}: AlertProps): React.ReactElement {
  return (
    <div className={cx('alert', `alert-${variant}`, className)} role="alert">
      {heading && <h4 className="alert-heading">{heading}</h4>}
      {children}
    </div>
  );
}
