import React, { forwardRef, Ref } from 'react';
import cx from 'classnames';
import { InputProps } from './types';

export const Input = forwardRef(function Input(
  props: InputProps,
  ref: Ref<HTMLInputElement>
) {
  const { size, error, className: outerClassname, inputSize, ...rest } = props;
  const className = cx(
    'form-control',
    size && `form-control-${size}`,
    {
      'is-invalid': error,
    },
    outerClassname
  );

  return <input className={className} size={inputSize} {...rest} ref={ref} />;
});

Input.defaultProps = {
  type: 'text',
};
