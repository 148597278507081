import { useCallback, useRef, useState } from 'react';
import eq from 'fast-deep-equal';
import { RequestSuccess, useAPI } from '../../api';
import { Entity } from '../types';
import { PageLoader, PageResult, ResultHandler } from './types';
import { serializeSort } from './sortUtils';

interface DRFPage<T> {
  results: T[];
  count: number;
  // TODO: unfiltered total
  total?: number;
}

function useFilterPropRef(filter?: Record<string, unknown>) {
  const ref = useRef<Record<string, unknown> | undefined>();

  if (!eq(filter, ref.current)) {
    ref.current = filter;
  }

  return ref.current;
}

function handleDRFPageResponse<T extends Entity>(
  request: RequestSuccess<DRFPage<T>>
): PageResult<T> {
  // TODO: unfiltered total
  const { results: data, count } = request.data;
  return {
    data,
    count,
  };
}

export function usePageLoader<T extends Entity, F = any>(
  url: string,
  handleResult: ResultHandler<T> = handleDRFPageResponse,
  outerFilter?: Record<string, unknown>
): PageLoader<T, F> {
  const [, /* state */ setState] = useState();

  const currentFilter = useFilterPropRef(outerFilter);

  const api = useAPI();
  return useCallback(
    async ({
      page,
      size: page_size,
      sort,
      filter,
    }): Promise<{ data: T[]; count: number }> => {
      const result = await api.get<any, unknown>(url, {
        queryParams: {
          page_size,
          page,
          ordering: serializeSort(sort),
          ...filter,
          ...currentFilter,
        },
      });

      if (result.ok) {
        return handleResult(result);
      }

      // FIXME: deal with 404?
      setState(() => {
        throw result.error;
      });
      throw result.error;
    },
    [api, url, currentFilter, handleResult]
  );
}
