import React from 'react';
import { useField } from 'react-final-form';
import { DatePicker } from '../../components';
import { fieldSubscription } from '../const';
import { FormGroup } from '../FormGroup';
import { useFieldProps } from '../useFieldProps';
import { DatePickerFieldProps } from './types';
import { parseDate, formatDate } from './utils';

export function DatePickerField(
  props: DatePickerFieldProps
): React.ReactElement {
  const {
    name,
    className,
    label,
    help,
    validate,
    maxDate,
    ...rest
  } = useFieldProps(props);

  const {
    input: { value: selected, ...input },
    meta,
  } = useField<Date | null, any>(name, {
    subscription: fieldSubscription,
    parse: parseDate,
    format: formatDate,
    validate,
  });

  const fieldProps = {
    ...input,
    selected,
    error: meta.invalid && meta.touched,
    ...rest,
    maxDate,
  };

  return (
    <FormGroup
      className={className}
      label={label}
      id={rest.id}
      help={help}
      meta={meta}
      required={fieldProps.required}
    >
      <DatePicker {...fieldProps} />
    </FormGroup>
  );
}
