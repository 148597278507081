import React, { useCallback } from 'react';
import { Input } from '../Input';
import { PaginationProps } from './types';
import { PageRange } from './PageRange';

export function Pagination(props: PaginationProps): React.ReactElement {
  const {
    page,
    totalPages,
    goToPage,
    pageSizes,
    pageSize,
    setPageSize,
  } = props;
  const onPageInputChange = useCallback(
    e => {
      const page = e.target.value ? parseInt(e.target.value, 10) : 1;
      if (page > 0 && page <= totalPages) {
        goToPage(page);
      }
    },
    [totalPages, goToPage]
  );

  const onPageSizeChange = useCallback(
    e => {
      setPageSize(parseInt(e.target.value, 10));
    },
    [setPageSize]
  );

  return (
    <nav className="d-flex d-flex align-items-center">
      <PageRange page={page} goToPage={goToPage} totalPages={totalPages} />
      <div className="form-inline ml-3 small">
        <span>
          Page
          <Input
            size="sm"
            className="mx-2 text-center"
            value={page.toString()}
            inputSize={totalPages.toString().length}
            onChange={onPageInputChange}
          />
          of {totalPages}
        </span>
        {pageSizes && (
          <span className="ml-2">
            <select
              className="form-control form-control-sm mx-2"
              onChange={onPageSizeChange}
              value={pageSize}
            >
              {pageSizes.map(size => (
                <option key={size} value={size}>
                  {size} / page
                </option>
              ))}
            </select>
          </span>
        )}
      </div>
    </nav>
  );
}
