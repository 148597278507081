import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormProps, FormData } from './types';
import { IsDisabledContext } from './IsDisabledContext';
import { FormError } from './FormError';

// eslint-disable-next-line @typescript-eslint/ban-types
export function Form<FV extends FormData = {}>(
  props: FormProps<FV>
): React.ReactElement {
  const {
    subscription = {
      submitting: true,
    },
    mutators = { ...arrayMutators },
    className,
    autoComplete,
    noValidate,
    children,
    disabled = false,
    renderErrors = true,
    ...rest
  } = props;
  return (
    <IsDisabledContext.Provider value={disabled}>
      <FinalForm<FV> subscription={subscription} mutators={mutators} {...rest}>
        {({ handleSubmit, submitting }) => (
          <form
            onSubmit={handleSubmit}
            className={className}
            autoComplete={autoComplete}
            noValidate={noValidate}
            data-form-submitting={submitting}
            data-form-disabled={disabled}
          >
            {renderErrors && <FormError />}
            {children}
          </form>
        )}
      </FinalForm>
    </IsDisabledContext.Provider>
  );
}
