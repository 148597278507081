const SYMBOLS = new RegExp(/[^\d.,-]/, 'g');
const LAST_DECIMAL = new RegExp(/.*([.,]).*$/);

export function guessDecimal(v: string): string {
  // only digits and possible decimal seperators, minus
  let clean = v.replace(SYMBOLS, '');
  const decimal = clean.match(LAST_DECIMAL)?.[1];
  if (decimal) {
    // strip the other one
    clean = clean.replace(decimal === '.' ? ',' : '', '');
    // normalize to .
    clean = clean.replace(decimal, '.');
  }
  return clean;
}
