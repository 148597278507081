import React from 'react';
import { useFormState } from 'react-final-form';
import { Alert } from '../components';

export function FormError(): React.ReactElement {
  const { dirtySinceLastSubmit, submitError, error, touched } = useFormState({
    subscription: {
      submitError: true,
      dirtySinceLastSubmit: true,
      error: true,
      dirty: true,
      touched: true,
    },
  });

  return (
    <>
      {submitError && !dirtySinceLastSubmit && (
        <Alert variant="danger">{submitError}</Alert>
      )}
      {error && touched && <Alert variant="danger">{error}</Alert>}
    </>
  );
}
