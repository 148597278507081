import React, { useCallback } from 'react';
import { useField } from 'react-final-form';
import { Input } from '../../components';
import { useNumberParser } from '../../hooks';
import { useFieldProps } from '../useFieldProps';
import { fieldSubscription } from '../const';
import { FormGroup } from '../FormGroup';
import { DecimalFieldProps } from './types';
import { guessDecimal } from './guessDecimal';

// TODO: DRY with TextField
function format(value: any): any {
  return value === null || value === undefined ? '' : value.toString();
}

export function DecimalField(props: DecimalFieldProps): React.ReactElement {
  const {
    name,
    className,
    label,
    help,
    precision,
    validate,
    ...rest
  } = useFieldProps(props);

  const parse = useNumberParser(precision);

  const { input, meta } = useField<string, HTMLInputElement>(name, {
    parse,
    format,
    subscription: fieldSubscription,
    allowNull: true,
    validate,
  });

  const onPaste = useCallback(
    (event: React.ClipboardEvent) => {
      event.clipboardData.items[0].getAsString(text => {
        input.onChange(guessDecimal(text));
      });
      event.preventDefault();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fieldProps = {
    onPaste,
    ...input,
    error: meta.invalid && meta.touched,
    ...rest,
  };

  return (
    <FormGroup
      className={className}
      label={label}
      id={rest.id}
      help={help}
      meta={meta}
      required={fieldProps.required}
    >
      <Input {...fieldProps} />
    </FormGroup>
  );
}
