import { stringify } from 'qs';

export function isObject(obj: any): obj is object {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

export function isBodyObject(obj: any): boolean {
  return isObject(obj) || Array.isArray(obj);
}

export function buildURL(
  url: string,
  queryParams?: any,
  baseURL?: string
): string {
  // FIXME: should we try to parse existing params from baseUrl and url?
  const queryString = queryParams
    ? stringify(queryParams, { addQueryPrefix: true })
    : '';

  // absolute url or no baseUrl
  if (url.startsWith('/') || !baseURL) {
    return `${url}${queryString}`;
  }

  // relative url
  return `${baseURL}/${url}${queryString}`;
}

export function isJSON(headers: Headers): boolean {
  const contentType = headers.get('Content-Type');
  return Boolean(contentType && ~contentType.indexOf('json'));
}

const emptyCodes = [
  204, // no content
  205, // reset content
];

export async function parseResponse(response: Response): Promise<any> {
  if (!~emptyCodes.indexOf(response.status)) {
    if (isJSON(response.headers)) {
      return response.json();
    }

    // FIXME: We should probably know what to parse and not guess
    return response.blob();
    // return Promise.reject(`Failed to parse`);
  }
  return Promise.resolve();
}
