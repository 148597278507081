import {
  useCallback,
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
} from 'react';
import { Entity } from '..';
import { usePageLoader } from './usePageLoader';
import { useQueryControls } from './useQueryControls';
import { UseListProps, ListState, ListInstance } from './types';

export function useList<T extends Entity>({
  url,
  filter,
  defaultPageSize,
  defaultSort,
  defaultFilter,
  handleResult,
}: UseListProps<T>): ListInstance<T> {
  const isMounted = useRef(true);
  const [controlState, controller] = useQueryControls<T>(
    defaultPageSize,
    defaultSort,
    defaultFilter
  );
  const loader = usePageLoader<T>(url, handleResult, filter);

  const [state, setState] = useState<ListState<T>>({
    loading: false,
    data: [],
    count: 0,
  });

  const fetchIdRef = useRef(0);

  const fetchPage = useCallback(
    async controlState => {
      const fetchId = ++fetchIdRef.current;
      isMounted.current && setState(state => ({ ...state, loading: true }));
      const res = await loader(controlState);

      if (isMounted.current && fetchId === fetchIdRef.current) {
        setState(state => ({ ...state, loading: false, ...res }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loader]
  );

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  useLayoutEffect(() => {
    fetchPage(controlState);
  }, [fetchPage, controlState]);

  const prevUrlRef = useRef(url);

  useEffect(() => {
    prevUrlRef.current = url;
  });

  if (state.data.length !== 0 && prevUrlRef.current !== url) {
    setState(state => ({ ...state, data: [], count: 0 }));
  }

  return {
    setState,
    ...state,
    ...controlState,
    ...controller,
  };
}
