import { SelectProps } from './types';

function flatten(arr: any): any[] {
  return arr.reduce(
    (acc: any, val: any) =>
      Array.isArray(val.options)
        ? acc.concat(flatten(val.options))
        : acc.concat(val),
    []
  );
}

function getValue(opts: any, val: any, getOptVal: any, isMulti: any): any {
  if (val === '') return '';
  if (val === null) return undefined;
  if (val === undefined) return undefined;

  const options = opts ? flatten(opts) : [];
  const value = isMulti
    ? options.filter(o => val.includes(getOptVal(o)))
    : options.find(o => getOptVal(o) === val);
  return value;
}

const defaultGetOptionValue = (opt: any): any => (opt ? opt.value : opt);

export function makeProps(props: SelectProps<any>): SelectProps<any> {
  if (props.simple) {
    const {
      value,
      defaultValue,
      getOptionValue = defaultGetOptionValue,
      onChange,
      ...rest
    } = props;
    // https://gitlab.ffit.lv/martins/ffsdk/-/issues/15;
    const opts = props.options
      ? props.options
      : props.defaultOptions && props.defaultOptions !== true
      ? props.defaultOptions
      : undefined;

    return {
      value: getValue(opts, value, getOptionValue, props.isMulti), // || null;
      defaultValue: getValue(opts, defaultValue, getOptionValue, props.isMulti),
      onChange: onChange
        ? (newValue: any, action: any) => {
            const value = newValue
              ? props.isMulti
                ? newValue.map(getOptionValue)
                : getOptionValue(newValue)
              : newValue;
            onChange(value, action); // : undefined,
          }
        : undefined,
      getOptionValue,
      ...rest,
    };
  } else if (props.onChange && props.isMulti) {
    /**
     * This is a workaround for case when clearing the last value from isMultiple value is null and not empty array,
     * we should probably have isNullable prop or something
     */

    const { onChange, ...rest } = props;
    return {
      onChange: (newValue: any, action: any) =>
        onChange(newValue === null ? [] : newValue, action),
      ...rest,
    };
  }

  return props;
}
