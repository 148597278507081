import React from 'react';
import { useField } from 'react-final-form';
import { Input, TextArea } from '../../components';
import { TextFieldProps, TextFieldBaseProps } from './types';
import { fieldSubscription } from '../const';
import { useFieldProps } from '../useFieldProps';
import { FormGroup } from '../FormGroup';

export const identity = (value: any): any => value;

export function TextField(
  props: TextFieldBaseProps & { type?: string }
): React.ReactElement;
export function TextField(
  props: TextFieldBaseProps & {
    multiline: true;
    rows?: number;
    autosize?: boolean;
  }
): React.ReactElement;
export function TextField(props: TextFieldProps): React.ReactElement {
  const {
    name,
    className,
    label,
    multiline,
    format,
    parse = identity,
    help,
    validate,
    datalist,
    ...rest
  } = useFieldProps(props);

  const { input, meta } = useField<
    string,
    HTMLInputElement | HTMLTextAreaElement
  >(name, {
    format,
    parse,
    subscription: fieldSubscription,
    validate,
  });

  const fieldProps = {
    ...input,
    error: meta.invalid && meta.touched,
    ...rest,
    list: datalist ? `${rest.id}-datalist` : undefined,
  };

  const Component = multiline ? TextArea : Input;

  return (
    <FormGroup
      className={className}
      label={label}
      id={rest.id}
      help={help}
      meta={meta}
      required={fieldProps.required}
    >
      <Component {...fieldProps} />
      {datalist && (
        <datalist id={fieldProps.list}>
          {datalist.map((value, idx) => (
            <option key={idx}>{value}</option>
          ))}
        </datalist>
      )}
    </FormGroup>
  );
}
