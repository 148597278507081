import React from 'react';
import cx from 'classnames';
import { ProgressProps, ProgressBarProps } from './types';
import { Variant } from '..';

export function ProgressBar({
  className,
  value,
  variant,
  striped,
}: ProgressBarProps): React.ReactElement {
  return (
    <div
      className={cx(
        'progress-bar',
        { 'progress-bar-striped': striped },
        className,
        variant && `bg-${variant}`
      )}
      role="progressbar"
      title={`${value}%`}
      style={{
        width: `${Math.min(value, 100)}%`,
      }}
    >
      {value}%
    </div>
  );
}
export function Progress(props: {
  className?: string;
  value: number;
  variant: Variant;
}): React.ReactElement;
export function Progress(props: {
  className?: string;
  children: React.ReactNode;
}): React.ReactElement;
export function Progress({
  value,
  children,
  className,
  variant,
}: ProgressProps): React.ReactElement {
  return (
    <div className={cx('progress', className)}>
      {value ? <ProgressBar value={value} variant={variant} /> : children}
    </div>
  );
}
