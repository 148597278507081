import React from 'react';
import cx from 'classnames';
import { getIn } from 'final-form';
import { TableProps } from './types';
import { Entity } from '..';
import { THead } from './THead';

// eslint-disable-next-line @typescript-eslint/ban-types
export function Table<T extends Entity = {}>(
  props: TableProps<T>
): React.ReactElement {
  const {
    columns,
    row,
    className,
    headClassName,
    bodyClassName,
    data,
    sort,
    onSort,
    tfoot,
  } = props;

  return (
    <table className={cx('table', className)}>
      <THead
        className={headClassName}
        columns={columns}
        sort={sort}
        onSort={onSort}
      />

      <tbody className={bodyClassName}>
        {data.map((dataRow, rowIndex) => {
          const className = row?.className
            ? row.className(dataRow, rowIndex)
            : undefined;
          const onRowClick = row?.onClick;
          const onClick = onRowClick
            ? (e: React.MouseEvent) => onRowClick(e, dataRow, rowIndex)
            : undefined;
          return (
            <tr key={rowIndex} className={className} onClick={onClick}>
              {columns.map(({ key, render, className }, colIdx) => {
                const value = render
                  ? render(dataRow, rowIndex, colIdx)
                  : (getIn(dataRow, key) as React.ReactNode);
                return (
                  <td className={className} key={colIdx}>
                    {value}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      {tfoot}
    </table>
  );
}
