import { RequestError, InternalError } from './error';
import {
  ApiClient,
  ApiOptions,
  RequestArgs,
  SimpleRequestArgs,
  RequestResult,
  RequestFailure,
  RequestSuccess,
  RequestBody,
} from './types';
import { buildURL, isJSON, isBodyObject, parseResponse } from './utils';

export class FetchClient implements ApiClient {
  options: ApiOptions;
  constructor(
    options: ApiOptions = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  ) {
    const { baseURL, ...rest } = options;
    this.options = {
      // Get rid of trailig slash
      baseURL: baseURL?.replace(/\/+$/, ''),
      ...rest,
    };
  }

  public get<R, E>(
    url: string,
    args?: SimpleRequestArgs
  ): Promise<RequestResult<R, E>> {
    return this.request<R, E>({
      url,
      method: 'GET',
      ...args,
    });
  }

  public post<R, E>(
    url: string,
    body?: RequestBody,
    args?: SimpleRequestArgs
  ): Promise<RequestResult<R, E>> {
    return this.request<R, E>({
      url,
      body,
      method: 'POST',
      ...args,
    });
  }

  put<R, E>(
    url: string,
    body?: RequestBody,
    args?: SimpleRequestArgs
  ): Promise<RequestResult<R, E>> {
    return this.request<R, E>({
      url,
      body,
      method: 'PUT',
      ...args,
    });
  }

  patch<R, E>(
    url: string,
    body?: RequestBody,
    args?: SimpleRequestArgs
  ): Promise<RequestResult<R, E>> {
    return this.request<R, E>({
      url,
      body,
      method: 'PATCH',
      ...args,
    });
  }

  delete<R, E>(
    url: string,
    args?: SimpleRequestArgs
  ): Promise<RequestResult<R, E>> {
    return this.request<R, E>({
      url,
      method: 'DELETE',
      ...args,
    });
  }

  public request<R, E>(args: RequestArgs): Promise<RequestResult<R, E>> {
    return this.createRequest<R, E>(args)();
  }

  public createRequest<R, E>(
    args: RequestArgs
  ): () => Promise<RequestResult<R, E>> {
    const {
      url,
      method,
      queryParams,
      body,
      abortController = new AbortController(),
      ...rest
    } = args;

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { baseURL, timeout, retries, before, after, headers } = {
      ...this.options,
      ...rest,
      headers: new Headers({
        // Merge headers
        ...this.options.headers,
        ...rest.headers,
      }),
    };

    if (body instanceof FormData) {
      // Let browser set Content-Type and boundary
      headers.delete('Content-Type');
    }

    const finalURL = buildURL(url, queryParams, baseURL);

    const options: RequestInit = {
      method,
      headers,
      body:
        isJSON(headers) && isBodyObject(body)
          ? JSON.stringify(body)
          : (body as BodyInit),
      signal: abortController.signal,
    };

    const request = new Request(finalURL, options);

    before && before(request);

    // @TODO: timeout
    const fetcher = (): Promise<Response> => fetch(request);

    // @TODO: retry
    const executeRequest = async (): Promise<RequestResult<R, E>> => {
      let response;
      let error;
      let data = undefined;
      // This is not great, status is 500 if request has not been made
      let status = 500;

      try {
        response = await fetcher();
        status = response.status;

        try {
          const value = await parseResponse(response);
          if (response.ok) {
            data = value as R;
          } else {
            data = value as E;
            if (response.status >= 400 && response.status <= 500) {
              error = new RequestError(response.statusText, response.status);
            } else {
              error = new InternalError(
                response.statusText || 'Unexpected error'
              );
            }
          }
        } catch (e) {
          error = new InternalError(`Failed to parse: ${e}`);
        }
      } catch (e) {
        if (e.name === 'AbortError') {
          error = e;
        } else {
          // Fixme
          error = new InternalError(`Failed to fetch: ${e}`);
        }
      }

      let result;
      if (error) {
        result = {
          ok: false,
          status,
          error,
          data,
          response,
        } as RequestFailure<E>;
      } else {
        result = {
          ok: true,
          status,
          error,
          data,
          response,
        } as RequestSuccess<R>;
      }

      after && after(result);

      return result;
    };

    return executeRequest;
  }
}
