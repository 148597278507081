import { Entity } from '..';
import { createContext, useContext } from 'react';
import { ListInstance } from './types';
import invariant from 'tiny-invariant';

export const ListContext = createContext<ListInstance<any> | undefined>(
  undefined
);

export function useList<T extends Entity>(): ListInstance<T> {
  const c = useContext(ListContext);
  invariant(c, 'List missing');
  return c;
}
