import React from 'react';
import cx from 'classnames';
import { SelectProps } from './types';
import ReactSelect, { OptionTypeBase, StylesConfig } from 'react-select';
import { default as ReactSelectAsync } from 'react-select/async';
import { makeProps } from './helpers';
import './Select.scss';

export const selectStyle: StylesConfig<any, any> = {
  // This doesn't have a classname for some reason
  input: base => ({
    ...base,
    margin: undefined,
    paddingBottom: undefined,
    paddingTop: undefined,
  }),
  // Allow vertical growth
  singleValue: base => ({
    ...base,
    position: undefined,
    top: undefined,
    transform: undefined,
  }),
  menu: base => ({
    ...base,
    // horizontal growth
    width: undefined,
    minWidth: '100%',
  }),
};

// TODO: overload types for Async/NonAsync
export function Select<
  OptionType extends OptionTypeBase = { label: string; value: string }
>(props: SelectProps<OptionType>): React.ReactElement {
  const {
    size,
    error,
    className: outerClassname,
    disabled,
    ...rest
  } = makeProps(props);
  const className = cx(
    'form-control',
    'form-control-select',
    size && `form-control-${size}`,
    {
      'is-invalid': error,
    },
    outerClassname
  );

  const selectProps = {
    className,
    styles: selectStyle,
    isDisabled: disabled,
    classNamePrefix: 'react-select',
    ...rest,
  };

  if (selectProps.loadOptions) {
    return (
      <ReactSelectAsync
        {...selectProps}
        loadOptions={selectProps.loadOptions}
      />
    );
  }

  return <ReactSelect {...selectProps} />;
}
