import { useCallback } from 'react';

// returns string|null
export function useNumberParser(precision = 2): (v: string) => any {
  const parser = useCallback(
    (v: string) => {
      // strip everything that is not number or separator
      const negative = v.startsWith('-');
      const strip = v.replace(',', '.').replace(/[^\d.]/g, '');
      if (strip === '') {
        if (negative) return '-';
        return null;
      }
      const rgx = new RegExp(`^(\\d*.\\d{0,${precision}}).*$`, 'g');
      return `${negative ? '-' : ''}${strip.replace(rgx, '$1')}`;
    },
    [precision]
  );

  return parser;
}
