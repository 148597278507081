import React, { createContext, useContext } from 'react';
import { ApiClient } from './types';
import invariant from 'tiny-invariant';

export const APIContext = createContext<ApiClient | undefined>(undefined);

export function useAPI(): ApiClient {
  const c = useContext(APIContext);
  invariant(c, 'APIContext missing');
  return c;
}

export function APIProvider({
  client,
  children,
}: {
  client: ApiClient;
  children: React.ReactNode;
}): React.ReactElement {
  return <APIContext.Provider value={client}>{children}</APIContext.Provider>;
}
