import React, { forwardRef, Ref } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { ButtonProps, BaseButtonProps } from './types';

type ButtonType = {
  // input
  (
    props: BaseButtonProps & {
      type?: 'button' | 'submit';
      value?: string;
    }
  ): React.ReactElement<any, any> | null;
  // a
  (
    props: ButtonProps & {
      href: string;
      children: React.ReactNode;
      target?: '_blank' | '_self' | '_parent' | '_top';
    }
  ): React.ReactElement<any, any> | null;
  // link
  (
    props: ButtonProps & {
      to: string;
      end?: boolean;
    }
  ): React.ReactElement<any, any> | null;
  displayName?: string;
};

export const Button: ButtonType = forwardRef(
  (props: any, ref: Ref<HTMLButtonElement> | Ref<HTMLAnchorElement>) => {
    const {
      variant = 'primary',
      size = 'md',
      className: outerClassname,
      disabled,
      active,
      testId,
      ...rest
    } = props;
    const buttonProps = {
      className: cx(
        'btn',
        `btn-${variant}`,
        `btn-${size}`,
        outerClassname,
        { disabled },
        { active }
      ),
      'aria-pressed': active,
      'data-testid': testId,
      ...rest,
    };

    if (props.hasOwnProperty('to')) {
      return (
        <Link
          {...buttonProps}
          to={rest.to}
          aria-disabled={disabled}
          ref={ref as Ref<HTMLAnchorElement>}
        />
      );
    }

    if (props.hasOwnProperty('href')) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          {...buttonProps}
          aria-disabled={disabled}
          ref={ref as Ref<HTMLAnchorElement>}
        />
      );
    }

    return (
      <button
        type="button"
        {...buttonProps}
        disabled={disabled}
        ref={ref as Ref<HTMLButtonElement>}
      />
    );
  }
);

Button.displayName = 'Button';
